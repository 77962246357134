/* playfair-display-400normal - latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Playfair Display Regular '),
    local('Playfair Display-Regular'),
    url('./files/playfair-display-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/playfair-display-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* playfair-display-400italic - latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Playfair Display Regular italic'),
    local('Playfair Display-Regularitalic'),
    url('./files/playfair-display-latin-400italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/playfair-display-latin-400italic.woff') format('woff'); /* Modern Browsers */
}

/* playfair-display-500normal - latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Playfair Display Medium '),
    local('Playfair Display-Medium'),
    url('./files/playfair-display-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/playfair-display-latin-500.woff') format('woff'); /* Modern Browsers */
}

/* playfair-display-500italic - latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('Playfair Display Medium italic'),
    local('Playfair Display-Mediumitalic'),
    url('./files/playfair-display-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/playfair-display-latin-500italic.woff') format('woff'); /* Modern Browsers */
}

/* playfair-display-600normal - latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Playfair Display SemiBold '),
    local('Playfair Display-SemiBold'),
    url('./files/playfair-display-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/playfair-display-latin-600.woff') format('woff'); /* Modern Browsers */
}

/* playfair-display-600italic - latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Playfair Display SemiBold italic'),
    local('Playfair Display-SemiBolditalic'),
    url('./files/playfair-display-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/playfair-display-latin-600italic.woff') format('woff'); /* Modern Browsers */
}

/* playfair-display-700normal - latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Playfair Display Bold '),
    local('Playfair Display-Bold'),
    url('./files/playfair-display-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/playfair-display-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* playfair-display-700italic - latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Playfair Display Bold italic'),
    local('Playfair Display-Bolditalic'),
    url('./files/playfair-display-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/playfair-display-latin-700italic.woff') format('woff'); /* Modern Browsers */
}

/* playfair-display-800normal - latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    local('Playfair Display ExtraBold '),
    local('Playfair Display-ExtraBold'),
    url('./files/playfair-display-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/playfair-display-latin-800.woff') format('woff'); /* Modern Browsers */
}

/* playfair-display-800italic - latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src:
    local('Playfair Display ExtraBold italic'),
    local('Playfair Display-ExtraBolditalic'),
    url('./files/playfair-display-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/playfair-display-latin-800italic.woff') format('woff'); /* Modern Browsers */
}

/* playfair-display-900normal - latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Playfair Display Black '),
    local('Playfair Display-Black'),
    url('./files/playfair-display-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/playfair-display-latin-900.woff') format('woff'); /* Modern Browsers */
}

/* playfair-display-900italic - latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Playfair Display Black italic'),
    local('Playfair Display-Blackitalic'),
    url('./files/playfair-display-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/playfair-display-latin-900italic.woff') format('woff'); /* Modern Browsers */
}

